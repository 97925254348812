import { PageContent, Tabs } from '@loadsmart/miranda-react'

import { WindowTitle } from '_shared_/components/WindowTitle'

import { PaymentsListPageHeader } from './PaymentsListPageHeader'

export function PaymentBasePage() {
  return (
    <>
      <WindowTitle title="Payments" />
      <PageContent data-testid="payments-list-page">
        <PaymentsListPageHeader />
        <PageContent.Body>
          <Tabs>
            <Tabs.Tab name="Exported">Exported</Tabs.Tab>
          </Tabs>
        </PageContent.Body>
      </PageContent>
    </>
  )
}
